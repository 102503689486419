import React, { useState, useEffect } from "react"
import { handleCreateNotification, handleUpdateNotification } from "../services/notification";
import { useUser, useUserDispatch } from "../store/UserContext";

export default function NotificationForm({ setShowForm, selectedNotification, setSelectedNotification }) {
  const user = useUser();
  const dispatch = useUserDispatch();
  const [updating] = useState(!!selectedNotification)


  const [notificationData, setNotificationData] = useState({
    _id: null,
    name: "",
    recipients: [],
    accounts: [],
    definition: "",
    active: true,
    count_refunds: false
  });


  useEffect(() => {
    if (!selectedNotification) return;

    setNotificationData({
      ...selectedNotification
    })

  }, [selectedNotification])

  const getRecipientsArr = e => {
    let id = e?.target?.name.split("_")[1];
    return e?.target?.checked
      // add recipient 
      ? [...notificationData?.recipients, id]
      // remove recipient
      : [...notificationData.recipients.filter(r => r !== id)]
  }



  const getAccountsArr = e => {
    const id_arr = e?.target?.name.split("_");
    const bank_id = id_arr[1];    // server generated value
    const account_id = id_arr[2]; // plaid generated value
    const { access_token } = user?.banks.find(b => b?._id === bank_id)

    return !e?.target?.checked
      // remove account
      ? [...notificationData?.accounts.filter(a => a.account_id !== account_id)]
      // add account
      : [
        ...notificationData?.accounts,
        {
          access_token,
          bank_id,
          account_id // this might need to be _id: account_id
        }
      ]
  }

  const handleChange = (e) => {

    // handle account change
    if (e?.target?.name?.split("_")[0] === "account") {
      const new_accounts_arr = getAccountsArr(e)
      setNotificationData({
        ...notificationData,
        accounts: [...new_accounts_arr]
      })
      return;
    }

    // handle recipient change
    if (e?.target?.name?.split("_")[0] === "recipient") {
      const new_recipients_arr = getRecipientsArr(e)
      setNotificationData({
        ...notificationData,
        recipients: [...new_recipients_arr]
      })
      return;
    }

    // handle all other changes
    setNotificationData({
      ...notificationData,
      [e.target.name]:
        e.target.name === "active"
          ? e.target.checked
          : e.target.value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const { _id, name, recipients, accounts } = notificationData;
    const { id, token } = user;
    if (updating) {
      handleUpdateNotification({ notification_id: _id, name, recipients, accounts, user_id: id, token, dispatch })
    } else {
      handleCreateNotification({ name, recipients, accounts, user_id: id, token, dispatch })
    }
    setShowForm(false)
  }

  const cancel = () => {
    setSelectedNotification(null)
    setShowForm(false)
  }

  return (
    <>
      {/* <button onClick={() => console.log({notificationData})}>log info</button> */}
      <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
        <div className="space-y-8">
          <div className="pt-8">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">Notification Details</h3>
              <p className="mt-1 text-sm text-gray-500">Set up a daily spending notification</p>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label htmlFor="notification-name" className="block text-sm font-medium text-gray-700">
                  Notification name
                </label>
                <div className="mt-1">
                  <input
                    id="notification-name"
                    name="name"
                    onChange={handleChange}
                    value={notificationData.name}
                    type="text"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                  />
                </div>
              </div>
              {/* <div className="sm:col-span-4">
              <label htmlFor="definition" className="block text-sm font-medium text-gray-700">
                Definition  update to daily weekly monthly
              </label>
              <div className="mt-1">
                <input
                  id="definition"
                  name="definition"
                  onChange={handleChange}
                  value={notificationData?.definition}
                  type="text"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                />
              </div>
            </div> */}
              {/* <div>
              <label className="text-base font-medium text-gray-900">Notifications</label>
              <p className="text-sm leading-5 text-gray-500">How do you prefer to receive notifications?</p>
              <fieldset className="mt-4">
                <legend className="sr-only">Notification method</legend>
                <div className="space-y-4">
                  {timezoneOptions.map((tz, i) => (
                    <div key={i} className="flex items-center">
                      <input
                        id={tz}
                        name="timezone"
                        type="radio"
                        defaultChecked={notificationData.timezone === 'email'}
                        className="h-4 w-4 border-gray-300 text-emerald-600 focus:ring-emerald-500"
                      />
                      <label htmlFor={notificationMethod.id} className="ml-3 block text-sm font-medium text-gray-700">
                        {notificationMethod.title}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div> */}
            </div>
          </div>
          <div className="mt-6">
            <fieldset className="mt-6">
              <legend className="contents text-base font-medium text-gray-900">Select Recipients</legend>
              <p className="text-sm text-gray-500">Select recipients to recieve a daily spending notification.</p>
              {user?.recipients?.map((recipient, i) => (
                <div key={i} className="mt-4 space-y-4">
                  <div className="flex items-center">
                    <input
                      id="recipient-check"
                      name={`recipient_${recipient?._id}`}
                      type="checkbox"
                      onChange={handleChange}
                      checked={!!notificationData?.recipients.find(nr => nr === recipient?._id)}
                      value={!!notificationData?.recipients.find(nr => nr === recipient?._id)}
                      className="h-4 w-4 border-gray-300 text-emerald-600 focus:ring-emerald-500"
                    />
                    <label htmlFor="recipient-check" className="ml-3 block text-sm font-medium text-gray-700">
                      {recipient?.name}
                    </label>
                  </div>
                </div>
              ))}
            </fieldset>
          </div>
          <div className="mt-6">
            <fieldset className="mt-6">
              <legend className="contents text-base font-medium text-gray-900">Select Accounts to Track</legend>
              <p className="text-sm text-gray-500">Select accounts to track daily spending on.</p>
              {user?.banks?.map((bank, i) => (
                <div key={i} className="mt-4 space-y-4">
                  <p className="text-sm">{bank?.bank_name}</p>
                  {bank?.accounts?.map((account, i) => (
                    <div key={i} className="flex items-center">
                      <input
                        id="account-check"
                        name={`account_${bank?._id}_${account?.account_id}`}
                        type="checkbox"
                        onChange={handleChange}
                        checked={!!notificationData?.accounts.find(a => a?.account_id === account?.account_id)}
                        value={!!notificationData?.accounts.find(a => a?.account_id === account?.account_id)}
                        className="h-4 w-4 border-gray-300 text-emerald-600 focus:ring-emerald-500"
                      />
                      <label htmlFor="bank-check" className="ml-3 block text-sm font-medium text-gray-700">
                        {account?.name} {account?.account_id}
                      </label>
                    </div>

                  ))}
                </div>
              ))}
            </fieldset>
          </div>
          {/* <div className="mt-6">
          <fieldset className="mt-6">
            <legend className="contents text-base font-medium text-gray-900">Set Active</legend>
            <p className="text-sm text-gray-500">These are delivered via SMS to your mobile phone.</p>
            <div className="mt-4 space-y-4">
              <div className="flex items-center">
                <input
                  id="active-true"
                  name="active"
                  type="checkbox"
                  onChange={handleChange}
                  value={notificationData?.active}
                  className="h-4 w-4 border-gray-300 text-emerald-600 focus:ring-emerald-500"
                />
                <label htmlFor="active-true" className="ml-3 block text-sm font-medium text-gray-700">
                    Active 
                </label>
              </div>
            </div>
          </fieldset>
        </div> */}
          {/* <div className="pt-8">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">Notifications</h3>
          <p className="mt-1 text-sm text-gray-500">
            We'll always let you know about important changes, but you pick what else you want to hear about.
          </p>
        </div>
        <div className="mt-6">
          <fieldset>
            <legend className="sr-only">By Email</legend>
            <div className="text-base font-medium text-gray-900" aria-hidden="true">
              By Email
            </div>
            <div className="mt-4 space-y-4">
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="comments"
                    name="comments"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="comments" className="font-medium text-gray-700">
                    Comments
                  </label>
                  <p className="text-gray-500">Get notified when someones posts a comment on a posting.</p>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="candidates"
                    name="candidates"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="candidates" className="font-medium text-gray-700">
                    Candidates
                  </label>
                  <p className="text-gray-500">Get notified when a candidate applies for a job.</p>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="offers"
                    name="offers"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="offers" className="font-medium text-gray-700">
                    Offers
                  </label>
                  <p className="text-gray-500">Get notified when a candidate accepts or rejects an offer.</p>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset className="mt-6">
            <legend className="contents text-base font-medium text-gray-900">Push Notifications</legend>
            <p className="text-sm text-gray-500">These are delivered via SMS to your mobile phone.</p>
            <div className="mt-4 space-y-4">
              <div className="flex items-center">
                <input
                  id="push-everything"
                  name="push-notifications"
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-emerald-600 focus:ring-emerald-500"
                />
                <label htmlFor="push-everything" className="ml-3 block text-sm font-medium text-gray-700">
                  Everything
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="push-email"
                  name="push-notifications"
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-emerald-600 focus:ring-emerald-500"
                />
                <label htmlFor="push-email" className="ml-3 block text-sm font-medium text-gray-700">
                  Same as email
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="push-nothing"
                  name="push-notifications"
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-emerald-600 focus:ring-emerald-500"
                />
                <label htmlFor="push-nothing" className="ml-3 block text-sm font-medium text-gray-700">
                  No push notifications
                </label>
              </div>
            </div>
          </fieldset>
        </div>
      </div> */}
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              onClick={() => cancel()}
              type="button"
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-emerald-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
            >
              {updating ? 'Update' : 'Save'}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
