import { useState, useEffect } from "react"
import NewBankForm from "../components/NewBankForm";
import BankList from "../components/BankList";
import { readUserBanks, deleteBank } from "../network/banks";
import { useUser } from "../store/UserContext";

export default function Banks() {
  const user = useUser();
  const [showForm, setShowForm] = useState(false)
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getBanks = async () => {
      setLoading(true);
      try {
        const data = await readUserBanks({ user_id: user.id, token: user.token });
        setBanks([...data?.data])
      } catch (error) {
        console.error("Error fetching banks");
      } finally {
        setLoading(false);
      }
    };
    getBanks();
  }, [user]);

  const handleDeleteBank = async ({ bank_id }) => {
    try {
      await deleteBank({ bank_id, user_id: user.id, token: user.token });
      setBanks(banks.filter(bank => bank._id !== bank_id));
    } catch (error) {
      console.error("Error deleting bank:", error);
    }
  };

  return (
    <div>
      <div className="mt-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
        {showForm
          ? <NewBankForm
            setShowForm={setShowForm}
          />
          : <BankList
            setShowForm={setShowForm}
            banks={banks}
            loading={loading}
            handleDeleteBank={handleDeleteBank}
          />
        }
      </div>
    </div>
  )
}
