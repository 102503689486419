import React, { useState, useEffect } from "react"
import { useUser, useUserDispatch } from "../store/UserContext";
import { handleCreateRecipient, handleUpdateRecipient } from "../services/recipient";

export default function RecipientForm({ setShowForm, selectedRecipient, setSelectedRecipient }) {

  const user = useUser();
  const dispatch = useUserDispatch();
  const [updating] = useState(!!selectedRecipient);

  const [recipientData, setRecipientData] = useState({
    _id: null,
    name: null,
    cell: null,
    email: null,
  });

  useEffect(() => {
    if (!selectedRecipient) return;

    setRecipientData({
      ...selectedRecipient
    })

  }, [selectedRecipient])

  const handleChange = (e) => {
    setRecipientData({
      ...recipientData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const { _id, name, cell, email } = recipientData;
    const { id, token } = user;
    if (updating) {
      handleUpdateRecipient({ recipient_id: _id, name, cell, email, user_id: id, token, dispatch })
    } else {
      handleCreateRecipient({ name, cell, email, user_id: id, token, dispatch, user })
    }
    setShowForm(false);
  }

  const cancel = () => {
    setSelectedRecipient(null)
    setShowForm(false)
  }


  return (
    <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
      <div className="space-y-8">
        <div className="pt-8">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">Recipient Details</h3>
            <p className="mt-1 text-sm text-gray-500">Enter information for the recipient you would like to add.</p>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label htmlFor="recipient-name" className="block text-sm font-medium text-gray-700">
                Recipient name
              </label>
              <div className="mt-1">
                <input
                  id="recipient-name"
                  name="name"
                  onChange={handleChange}
                  value={recipientData.name}
                  type="text"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="sm:col-span-4">
              <label htmlFor="recipient-cell" className="block text-sm font-medium text-gray-700">
                Cell phone
              </label>
              <div className="mt-1">
                <input
                  id="recipient-cell"
                  name="cell"
                  onChange={handleChange}
                  value={recipientData.cell}
                  type="tel"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="sm:col-span-4">
              <label htmlFor="recipient-email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1">
                <input
                  id="recipient-email"
                  name="email"
                  onChange={handleChange}
                  value={recipientData?.email}
                  type="email"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="pt-8">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">Notifications</h3>
            <p className="mt-1 text-sm text-gray-500">
              We'll always let you know about important changes, but you pick what else you want to hear about.
            </p>
          </div>
          <div className="mt-6">
            <fieldset>
              <legend className="sr-only">By Email</legend>
              <div className="text-base font-medium text-gray-900" aria-hidden="true">
                By Email
              </div>
              <div className="mt-4 space-y-4">
                <div className="relative flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      id="comments"
                      name="comments"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="comments" className="font-medium text-gray-700">
                      Comments
                    </label>
                    <p className="text-gray-500">Get notified when someones posts a comment on a posting.</p>
                  </div>
                </div>
                <div className="relative flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      id="candidates"
                      name="candidates"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="candidates" className="font-medium text-gray-700">
                      Candidates
                    </label>
                    <p className="text-gray-500">Get notified when a candidate applies for a job.</p>
                  </div>
                </div>
                <div className="relative flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      id="offers"
                      name="offers"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="offers" className="font-medium text-gray-700">
                      Offers
                    </label>
                    <p className="text-gray-500">Get notified when a candidate accepts or rejects an offer.</p>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset className="mt-6">
              <legend className="contents text-base font-medium text-gray-900">Push Notifications</legend>
              <p className="text-sm text-gray-500">These are delivered via SMS to your mobile phone.</p>
              <div className="mt-4 space-y-4">
                <div className="flex items-center">
                  <input
                    id="push-everything"
                    name="push-notifications"
                    type="radio"
                    className="h-4 w-4 border-gray-300 text-emerald-600 focus:ring-emerald-500"
                  />
                  <label htmlFor="push-everything" className="ml-3 block text-sm font-medium text-gray-700">
                    Everything
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id="push-email"
                    name="push-notifications"
                    type="radio"
                    className="h-4 w-4 border-gray-300 text-emerald-600 focus:ring-emerald-500"
                  />
                  <label htmlFor="push-email" className="ml-3 block text-sm font-medium text-gray-700">
                    Same as email
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id="push-nothing"
                    name="push-notifications"
                    type="radio"
                    className="h-4 w-4 border-gray-300 text-emerald-600 focus:ring-emerald-500"
                  />
                  <label htmlFor="push-nothing" className="ml-3 block text-sm font-medium text-gray-700">
                    No push notifications
                  </label>
                </div>
              </div>
            </fieldset>
          </div>
        </div> */}
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            onClick={() => cancel()}
            type="button"
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-emerald-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
          >
            {updating ? 'Update' : 'Save'}
          </button>
        </div>
      </div>
    </form>
  )
}
