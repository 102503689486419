import React, { useEffect, useState } from "react"
import { CalendarIcon, BanknotesIcon, PaperAirplaneIcon, CurrencyDollarIcon, ClockIcon } from '@heroicons/react/24/outline'
import { useUser } from "../store/UserContext";
import { useUserDispatch } from "../store/UserContext";
import { readBudgets } from '../network/budget'

const items = [
  {
    name: 'Link a bank',
    description: 'After linking a bank account, you can select accounts from that bank to track daily, weekly, and monthly spending.',
    href: '/banks',
    iconColor: 'bg-emerald-500',
    icon: BanknotesIcon,
  },
  {
    name: 'Add a notification recipient',
    description: 'Enter the name and cell number of those you would like to send spending updates.',
    href: '/recipients',
    iconColor: 'bg-emerald-500',
    icon: PaperAirplaneIcon,
  },
  {
    name: 'Schedule the notification',
    description: 'Add your linked bank accounts and recipients to the notification, start getting updates today.',
    href: '/notifications',
    iconColor: 'bg-emerald-500',
    icon: CalendarIcon,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Dashboard() {
  const user = useUser()
  console.log('user', user)
  const dispatch = useUserDispatch();
  const [budgets, setBudgets] = useState(user?.budgets?.length ? user?.budgets : [])
  const [selectedBudget, setSelectedBudget] = useState(user?.budgets?.length ? user?.budgets[1] : null)

  useEffect(() => {
    if (user) {
      const fetchBudgets = async () => {
        if (user && user.id && user.token) {
          try {
            const { data } = await readBudgets({ user_id: user.id, token: user.token });
            console.log('budgets', data.budgets)
            if (data && data.budgets) {
              setBudgets([...data?.budgets]);
              setSelectedBudget(data?.budgets[0]);
            }
          } catch (error) {
            console.error('Error fetching budgets:', error);
          }
        }
      };

      fetchBudgets();
    }
  }, [user])

  const handleTabChange = (name) => {
    setSelectedBudget(budgets.find(budget => budget.name === name))
  }

  const refreshBudget = async ({ budget_id }) => {
    const { id, token } = user;
    const { data } = await readBudgets({ user_id: id, token, dispatch, user })
    setBudgets([...data.budgets])
    setSelectedBudget(data?.budgets?.length ? data?.budgets[0] : null)
  }

  // Calculate progress values
  const monthlyTotal = selectedBudget?.monthlyTotal?.amount || 0;
  const spendingLimit = selectedBudget?.spendingLimit || 0;
  const currentDay = new Date().getDate();
  const daysInMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
  const daysLeftInMonth = daysInMonth - currentDay;
  const monthlySpentPercentage = (monthlyTotal / spendingLimit) * 100;
  const daysLeftPercentage = (daysLeftInMonth / daysInMonth) * 100;
  const daysElapsedPercentage = ((currentDay - 1) / daysInMonth) * 100;


  return (
    <>
      <div className="mx-auto max-w-lg pt-16">
        {/* 
        show big number, how much is left, 
        get 
        think of parent category for collection of accounts to track, maybe budget?
        get all transactions for account in that budget
        progress bar below that */}
        {budgets?.length ?
          <div>
            <div className="border-b border-gray-200 pb-5 sm:pb-0">
              <h3 className="text-base font-semibold leading-6 text-gray-900">Budgets</h3>
              <div className="mt-3 sm:mt-4">

                <div className="">
                  <nav className="-mb-px flex space-x-8">
                    {budgets.map((budget) => (
                      <a
                        onClick={() => handleTabChange(budget?.name)}
                        key={budget?.name}
                        className={classNames(
                          (selectedBudget?.name === budget?.name)
                            ? 'border-emerald-500 text-emerald-600'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                          'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                        )}
                        aria-current={budget.current ? 'page' : undefined}
                      >
                        {budget.name}
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="my-8 flex justify-center">
                <div className="flex">
                  <div className="flex ">
                    <h1 className={classNames(
                      (selectedBudget?.monthlyTotal?.amount <= selectedBudget?.spendingLimit)
                        ? "text-4xl pt-4 text-emerald-600"
                        : "text-4xl pt-4 text-red-600"
                    )} >$</h1>

                    <h1 className={classNames(
                      (selectedBudget?.monthlyTotal?.amount <= selectedBudget?.spendingLimit)
                        ? "text-8xl text-emerald-600"
                        : "text-8xl text-red-600"
                    )}>{`${selectedBudget?.monthlyTotal?.amount}`.split('.')[0]}</h1>
                  </div>
                  <div>
                    <h1 className={classNames(
                      (selectedBudget?.monthlyTotal?.amount <= selectedBudget?.spendingLimit)
                        ? "text-xl pt-14 text-emerald-600"
                        : "text-xl pt-14 text-red-600"
                    )}>{'.' + `${selectedBudget?.monthlyTotal?.amount}`.split('.')[1]}</h1>
                  </div>
                </div>
              </div>

              {/* Progress Bars */}
              <div className="my-4 flex justify-center items-center">
                <div className="relative w-4 h-4 mr-4 rounded-full">
                  <CurrencyDollarIcon className="h-4 w-4 text-black absolute left-0 top-0 mt-0.25 ml-0.25" /> {/* Dollar icon */}
                </div>
                <div className="relative w-3/6 h-2  bg-gray-300 rounded-full">
                  <div className={`absolute inset-y-0 ${monthlySpentPercentage > 100 ? 'bg-red-600' : 'bg-emerald-600'} rounded-full`} style={{ width: `${Math.min(100, monthlySpentPercentage)}%` }}></div>
                </div>
              </div>

              <div className="my-4 flex justify-center items-center">
                <div className="relative w-4 h-4 mr-4 rounded-full">
                  <ClockIcon className="h-4 w-4 text-black absolute left-0 top-0 mt-0.25 ml-0.25" /> {/* Clock icon */}
                </div>
                <div className="relative w-3/6 h-2 bg-gray-300 rounded-full">
                  <div className={`absolute inset-y-0 bg-black rounded-full`} style={{ width: `${Math.min(100, daysElapsedPercentage)}%` }}></div>
                </div>
              </div>











              <button
                type="button"
                className=" mb-8 rounded bg-emerald-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                onClick={() => refreshBudget({ budget_id: selectedBudget?._id })}
              >
                Refresh Totals
              </button>
            </div>

          </div>
          : <>
            {/* going to need to show the UI below for new users */}
            <h2 className="text-base font-semibold leading-6 text-gray-900">Create your first notification</h2>
            <p className="mt-1 text-sm text-gray-500">Get started by selecting a template or start from an empty project.</p>
            <ul className="mt-6 divide-y divide-gray-200 border-b border-t border-gray-200">
              {items.map((item, itemidx) => (
                <li key={itemidx}>
                  <div className="group relative flex items-start space-x-3 py-4">
                    <div className="flex-shrink-0">
                      <span
                        className={classNames(item.iconColor, 'inline-flex h-10 w-10 items-center justify-center rounded-lg')}
                      >
                        <item.icon className="h-6 w-6 text-black" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1">
                      <div className="text-sm font-medium hover:underline text-gray-900">
                        <a href={item.href}>
                          <span className="absolute inset-0 " aria-hidden="true" />
                          {item.name}
                        </a>
                      </div>
                      <p className="text-sm text-gray-500">{item.description}</p>
                    </div>
                    <div className="flex-shrink-0 self-center">
                      <chevronrighticon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <div className="mt-6 flex">
              <a href="/banks" className="text-sm font-medium text-emerald-600 hover:text-emerald-500">
                link a new bank account
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          </>
        }
      </div >

    </>
  )
}




