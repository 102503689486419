import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useUser, useUserDispatch } from "../store/UserContext";
import { handleDeleteBankLink } from "../services/bank";
import { readBank } from "../network/banks";
import NewBankForm from "./NewBankForm";
import { useNavigate } from "react-router-dom";
import moment from 'moment';

export default function Bank(props) {
  const user = useUser();
  const { bank_id } = useParams();
  const [bank, setBank] = useState(null)
  const [loading, setLoading] = useState(false);
  const dispatch = useUserDispatch();
  const [showLinkBank, setShowLinkBank] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    const getBank = async () => {
      setLoading(true);
      try {
        const { data } = await readBank({ bank_id, user_id: user.id, token: user.token });
        setBank({ ...data })
      } catch (error) {
        console.error("Error fetching bank");
      } finally {
        setLoading(false);
      }
    };
    getBank();
  }, [bank_id, user.id, user.token]);

  const deleteBankClicked = async () => {
    const { id, token } = user;
    await handleDeleteBankLink({ bank_id, user_id: id, token, dispatch })
    navigate('/banks')
  }

  const viewAccountClicked = (account_id) => {
    navigate('/account', { state: { account_id } })
  }

  if (loading) {
    return (
      <div className="px-4 pt-8 sm:px-6 lg:px-8">
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-600"></div>
        </div>
      </div>
    )
  }

  if (showLinkBank) {
    return (
      <NewBankForm
        accessToken={bank?.access_token}
        setShowForm={setShowLinkBank}
        bank_id={bank?.bank_id}
      />
    )
  }

  if (!bank) {
    return <div>Bank not found</div>
  }

  return (
    <div className="mt-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="px-4 pt-8 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">{bank.bank_name}</h1>
            <p className="mt-2 text-sm text-gray-700">
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
              onClick={() => setShowLinkBank(true)}
            >
              Refresh Authentication
            </button>
          </div>
        </div>

        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Type
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Date
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                        Balance
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {bank.accounts.map((account, i) => (
                      <tr key={i} className="hover:bg-blue-100 cursor-pointer" onClick={() => viewAccountClicked(account._id)}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {account?.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{account?.subtype}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {account?.latest_balance?.date_created ? moment(account.latest_balance.date_created).format('MM/DD hh:mm a') : 'No date'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                          ${account?.latest_balance?.amount?.toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 flex sm:flex-none">
          <button
            type="button"
            onClick={deleteBankClicked}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
          >
            Delete Bank
          </button>
        </div>
      </div>
    </div>
  )
}