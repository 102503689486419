import React from 'react';
import { useNavigate } from "react-router-dom";
import { useUser } from "../store/UserContext";
import moment from 'moment';
export default function BankList({ setShowForm, banks, handleDeleteBank, loading }) {
  const user = useUser();
  const navigate = useNavigate();
  const viewBankClicked = (bank_id) => {
    navigate(`/users/${user?.id}/banks/${bank_id}`)
  }

  const refreshAccountTotals = async () => {
    console.log('refreshAccountTotals')
  }

  if (loading) {
    return (
      <div className="px-4 pt-8 sm:px-6 lg:px-8">
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-600"></div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="px-4  sm:px-6 lg:px-8">
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-3/4">
                        Banks & Accounts
                      </th>
                      <th scope="col" className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 w-1/4">
                        <button
                          onClick={() => {
                            refreshAccountTotals()
                          }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                          </svg>
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {banks?.length > 0 ? banks?.map((bank, i) => (
                      <tr key={i} className="hover:bg-blue-100">
                        <td colSpan="2" className="py-4 pl-4 pr-3 sm:pl-6">
                          <div
                            className="text-sm font-medium text-gray-900 underline cursor-pointer"
                            onClick={() => { viewBankClicked(bank._id) }}
                          >
                            {bank?.bank_name}
                            <span className="text-red-500">{bank?.auth_refresh_required ? ' - Authentication Refresh Required' : ''}</span>
                          </div>
                          <div className="mt-2 space-y-2">
                            {bank.accounts?.map((account, j) => (
                              <div key={j} className="flex items-center">
                                <div className="flex-1 text-sm text-gray-600">
                                  <span className="sm:hidden">{account.name.length > 17 ? account.name.substring(0, 17) + '...' : account.name}</span>
                                  <span className="hidden sm:inline">{account.name}</span>
                                </div>
                                <div className="text-sm text-gray-500 mr-4">
                                  {account?.latest_balance?.date_created ?
                                    moment(account.latest_balance.date_created).isSame(moment(), 'day') ?
                                      moment(account.latest_balance.date_created).format('hh:mm A') :
                                      moment(account.latest_balance.date_created).format('MM/DD')
                                    : 'No date'}
                                </div>
                                <div className={`text-sm font-medium w-32 text-right ${account.type === 'credit' && account.balance > 0 ? 'text-red-500' : 'text-gray-900'}`}>
                                  ${(account?.latest_balance?.amount?.toFixed(2) || '00.00')}
                                </div>
                              </div>
                            ))}
                          </div>
                        </td>
                      </tr>
                    )) : <tr><td colSpan="2" className="text-center">No banks found</td></tr>}
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end my-6">
          <button
            type="button"
            onClick={() => setShowForm(true)}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
          >
            Link New Bank
          </button>
        </div>

      </div>
    </>
  )
}