import { useUser, useUserDispatch } from "../store/UserContext";
import { deleteBudget, updateBudget } from "../network/budget";

export default function BudgetList({
  setShowForm,
  setSelectedBudget,
  budgets,
  onDeleteBudget
}) {
  const testBudgetClicked = (e, budget_id) => {
    // e.stopPropagation();
    // const budget = user?.budgets?.find(b => b._id === budget_id)
    // const access_tokens = budget.accounts.map(account => account?.access_token)
    // console.log({ access_tokens })
    // handleTestBudget({ budget_id, user_id: user?.id, token: user?.token })
  }

  const selectBudget = (budget_id) => {
    const budget = budgets?.find(b => b._id === budget_id)
    setSelectedBudget({ ...budget });
    setShowForm(true)
  }

  const handleDelete = (e, budget_id) => {
    e.stopPropagation();
    onDeleteBudget(budget_id);
  }

  return (
    <>
      <div className="px-4 pt-8 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Budgets</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the budgets you've created.
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              onClick={() => setShowForm(true)}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
            >
              Create Budget
            </button>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Recipients
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        # Accounts Tracked
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Test
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {budgets?.map((budget, i) => (
                      <tr
                        key={i}
                        className="hover:bg-blue-100 cursor-pointer"
                        onClick={() => selectBudget(budget?._id)}
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {budget?.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{
                          !!budget.recipients
                            ? budget.recipients.length
                            : 'N/A'
                        }</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{budget?.accounts?.length}</td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">
                          <p onClick={e => testBudgetClicked(e, budget?._id)} className="text-emerald-600 hover:underline hover:text-emerald-900 hover:cursor-pointer">
                            Test
                          </p>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">
                          <p onClick={e => handleDelete(e, budget?._id)} className="text-red-600 hover:underline hover:text-red-900 hover:cursor-pointer">
                            Delete
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}