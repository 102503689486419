import { signin, signup, resetPassword } from "../network/auth"

const handleSignup = async (args) => {
  const { email, password, dispatch, navigate } = args;

  Promise.resolve().then(async () => {
    let user = await signup(email, password);
    if (!user) {
      alert('error signing up')
      return;
    }
    dispatch({
      type: 'updated',
      user
    })
    navigate('/dashboard')
  }).catch(error => {
    alert(error);
  })
}

const handleSignin = async (args) => {
  const { email, password, dispatch, navigate } = args;

  Promise.resolve().then(async () => {
    let data = await signin(email, password);

    if (data?.error || !data?.data) {
      alert('error signing in')
      return;
    }

    const user = data.data;
    dispatch({
      type: 'updated',
      user: { ...user }
    });
    navigate(`/users/${user?.id}/banks`)
  }).catch(error => {
    alert(error);
  })
}
const handleResetPassword = async (args) => {
  const { new_password, token, user_id, email, dispatch } = args;

  Promise.resolve().then(async () => {
    let user = await resetPassword({
      new_password,
      token,
      user_id,
      email
    });

    if (user?.error) {
      alert('error resetting password')
      return;
    }
    dispatch({
      type: 'updated',
      user
    });
  }).catch(error => {
    alert(error);
  })
}

export { handleSignin, handleSignup, handleResetPassword }