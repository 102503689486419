import { base_url } from ".";
import toast from "react-hot-toast";

const URL = `${base_url}`;

const readBank = async ({ user_id, token, bank_id }) => {

  try {
    const response = await fetch(`${URL}/users/${user_id}/banks/${bank_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    console.log('error reading bank')
  }
}

const readUserBanks = async ({ user_id, token }) => {

  try {
    const response = await fetch(`${URL}/users/${user_id}/banks`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      }
    });
    return await response.json();

  } catch (error) {
    console.log('error')
  }

}
const setAuthRefresh = async (args) => {
  const {
    user_id,
    bank_id,
    token
  } = args;

  const response = await fetch(`${URL}/banks/set_auth_refresh`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      bank_id,
      user_id
    })
  });
  return await response.json();
}

// todo create bank link in serverless
// rip out all server code, go all in on serverless
// should have name, accts on bank..
// this is borked
const createBankLink = async ({ user_id, token, bank }) => {
  try {


    const response = await fetch(`${URL}/users/${user_id}/banks`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        bank
      })
    });
    if (response.ok) {
      toast.success('Bank linked successfully');
      const data = await response.json();
      return data;
    } else {
      throw new Error('Failed to create bank link');
    }
  } catch (error) {
    toast.error('Failed to link bank');
  }

}


const deleteBank = async ({ bank_id, user_id, token }) => {

  const response = await fetch(`${URL}/users/${user_id}/banks/${bank_id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
    }
  })
  return await response.json();
}

export { createBankLink, setAuthRefresh, readUserBanks, readBank, deleteBank }