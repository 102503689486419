import { base_url } from ".";
import { toast } from 'react-toastify';

const createBudget = async ({ user_id, token, budget }) => {
  try {
    const response = await fetch(
      `${base_url}/users/${user_id}/budgets`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        budget,
      })
    });

    if (!response.ok) {
      throw new Error('Unable to create budget');
    }

    toast.success('Budget created successfully');
    return await response.json();
  } catch (error) {
    toast.error('Failed to create budget');
  }
}

const readBudgets = async ({ user_id, token }) => {
  try {
    const response = await fetch(`${base_url}/users/${user_id}/budgets`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('unable to read budgets')
    }

    return await response.json();
  } catch (error) {
    console.log('error')
  }
}
const readBudget = async ({ user_id, token, budget_id }) => {
  try {
    const response = await fetch(`${base_url}/users/${user_id}/budgets/${budget_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('unable to read budget')
    }

    const { data } = await response.json();
    return data;
  } catch (error) {
    console.log('error')
  }
}

const updateBudget = async ({ budget_id, user_id, token, budget }) => {
  try {
    const response = await fetch(
      `${base_url}/users/${user_id}/budgets/${budget_id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Unable to update budget');
    }

    toast.success('Budget updated successfully');
    return await response.json();
  } catch (error) {
    toast.error('Failed to update budget');
  }
}


const deleteBudget = async ({ budget_id, user_id, token }) => {
  try {
    const response = await fetch(
      `${base_url}/users/${user_id}/budgets/${budget_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Unable to delete budget');
    }

    toast.success('Budget deleted successfully');
  } catch (error) {
    toast.error('Failed to delete budget');
  }
}

export { createBudget, readBudget, deleteBudget, updateBudget, readBudgets }