import { createContext, useContext, useReducer } from 'react';
export const AuthContext = createContext(null);
export const AuthDispatchContext = createContext(null);

export function AuthProvider({ children }) {
  const [authenticated, dispatch] = useReducer(
    authReducer,
    initialAuth
  )

  return (
    <AuthContext.Provider value={authenticated}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}

export function useAuthDispatch() {
  return useContext(AuthDispatchContext)
}

function authReducer(authenticated, action) {
  switch (action?.type) {
    case 'updated': {
      return {
        authenticated: action?.authenticated
      }
    }
    case 'deleted': {
      return null;
    }
    default: {
      throw Error('Unkown action: ', action?.type)
    }
  }
}

const initialAuth = false;